import React, { useEffect, useState } from "react";
import logo from "../assets/images/Alienform.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../store/useAuthStore";
import contractAbi from "../assets/Abi/contractAbi.json";
import bscContractAbi from "../assets/Abi/bscContractAbi.json";
import { ethers, Contract, formatEther } from "ethers";
import axios from "axios";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Header() {
  const { address, isConnected } = useAccount();
  const [balance, setBalance] = useState(0);
  const [balanceBsc, setBalanceBsc] = useState(0);
  const [subDetail, setSubDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tokenAddress = "0xf92D62Ed69242D655E685C96B98f32F1409c3262";
  const location = useLocation();
  const navigate = useNavigate();
  const token = useAuthStore((state) => state.token);
  const setToken = useAuthStore((state) => state.setToken);
  const clearToken = useAuthStore((state) => state.clearToken);

  const [username, setUserName] = useState("");

  const handleCustomerLogic = async (address) => {
    const apiUrl =
      "https://k4yiqqv97h.execute-api.us-east-1.amazonaws.com/api/generateLoginToken";
    const data = { customer_username: address };

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = response.data;
      console.log("Success:", responseData);
      // Proceed with any additional logic upon successful response
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    }
  };

  let query = useQuery();

  useEffect(() => {
    setIsLoading(!isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (!isLoading) {
      if (location.pathname === "/subscription" && isConnected == false) {
        if (isConnected === false || token == undefined) {
          navigate("/");
        }
      }
    }
  }, [location, navigate, token, isLoading, address, isConnected]);

  const [url, setUrl] = useState(localStorage.getItem("url"));

  useEffect(() => {
    const provider = new ethers.JsonRpcProvider(
      "https://mainnet.infura.io/v3/88a2d28b21a040f19d0c6df3aa34bce8"
    );
    const bscProvider = new ethers.JsonRpcProvider(
      "https://bsc-dataseed.binance.org/"
    );
    if (!isConnected || !address) {
      return;
    }

    const getBalance = async () => {
      const tokenAddress = "0xf92D62Ed69242D655E685C96B98f32F1409c3262";
      const contract = new Contract(tokenAddress, contractAbi, provider);
      try {
        const balance = await contract.balanceOf(address);
        // const formattedBalance = balance / 10 ** 9;
        const formattedBalance = Number(balance) / Number(10n ** 9n);

        console.log("formate ETH", formattedBalance);
        setBalance(formattedBalance);
      } catch (error) {
        console.error("Error fetching balance:", error);
        setBalance("Error");
      }
    };

    const getBalanceBsc = async () => {
      const tokenAddress = "0xf92D62Ed69242D655E685C96B98f32F1409c3262";
      const contract = new Contract(tokenAddress, bscContractAbi, bscProvider);
      try {
        const balance = await contract.balanceOf(address);

        // const formattedBalance = balance / 10 ** 9;
        const formattedBalance = Number(balance) / Number(10n ** 9n);
        setBalanceBsc(formattedBalance);
        console.log("Bsc Balance", formattedBalance);
      } catch (error) {
        console.error("Error fetching balance:", error);
        setBalanceBsc("Error");
      }
    };

    getBalance();
    getBalanceBsc();
  }, [isConnected, address]);

  useEffect(() => {
    if (address == undefined || !isConnected) {
      return;
    }

    const getSubscriptions = async () => {
      const apiUrl =
        "https://k4yiqqv97h.execute-api.us-east-1.amazonaws.com/login";
      const data = {
        username: address,
      };
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        if (responseData.subscriptions.length > 0) {
          setSubDetail(responseData.subscriptions);
        }
        // Proceed with any additional logic upon successful response
      } catch (error) {
        console.error("Error:", error);
        // alert("Error to GetSubscriction");
      }
    };

    getSubscriptions();
  }, [address]);

  // Effect to set up the storage listener
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "token") {
        const newToken = localStorage.getItem("token");
        setToken(newToken); // This ensures that token state is updated whenever the localStorage token changes
      } else if (event.key === "url") {
        setUrl(localStorage.getItem("url"));
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [token, localStorage.getItem("token")]);

  const redirectToTrading = async (isUser) => {
    // if (isConnected) {
    //   if (subDetail.length > 0) {
    //     const today = new Date();
    //     const end = new Date(subDetail[0].end_date);
    //     if (end > today) {
    //       if (balance > 1000000 || balanceBsc > 1000000) {
    //         const apiUrl = "https://k4yiqqv97h.execute-api.us-east-1.amazonaws.com/login";
    //         const data = {
    //           username: address,
    //         };

    //         try {
    //           const response = await fetch(apiUrl, {
    //             method: "POST",
    //             headers: {
    //               "Content-Type": "application/json",
    //               // Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify(data),
    //           });

    //           if (!response.ok) {
    //             throw new Error("Network response was not ok");
    //           }

    //           const responseData = await response.json();
    //           if (responseData.device_type === "Android") {
    //             window.open(responseData.login_token, "_blank");
    //           } else {
    //             window.location.href = responseData.login_token;
    //           }
    //         } catch (error) {
    //           console.error("Error:", error);
    //         }
    //       } else {
    //         alert("Insufficient A4M Tokens need minimum 1 Million A4M tokens");
    //         navigate("/subscription");
    //       }
    //     } else {
    //       alert("subscription expired");
    //       navigate("/subscription");
    //     }
    //   } else {
    //     alert("No Subscription Found");
    //     navigate("/subscription");
    //   }
    // } else {
    //   alert("Please Connect Wallet");
    // }

    if (isConnected) {
      const apiUrl =
        "https://k4yiqqv97h.execute-api.us-east-1.amazonaws.com/login";
      const data = {
        username: address,
      };
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("Response Data", responseData);
        if (balance > 1000000 || balanceBsc > 1000000) {
          if (responseData.deviceType === "Android") {
            window.open(responseData.tokenUrl, "_blank");
          } else {
            window.location.href = responseData.tokenUrl;
          }
        } else {
          alert("Insufficient A4M Tokens need minimum 1 Million A4M tokens");
          navigate("/subscription");
        }

        // Proceed with any additional logic upon successful response
      } catch (error) {
        alert("No active subscription found. ");
        navigate("/subscription");
        // alert("Error to GetSubscriction");
      }
    } else {
      alert("Please Connect An Wallet");
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      handleCustomerLogic(address);
    }
  }, [isConnected, address]);

  // const getUserDetail = async () => {
  //   const apiUrl = "https://k4yiqqv97h.execute-api.us-east-1.amazonaws.com/api/verifyToken";

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const responseData = await response.json();
  //     console.log("Success:", responseData);
  //     setUserName(responseData.detail.customer_username);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (token) {
  //     getUserDetail();
  //   }
  // }, [token]);
  return (
    <div>
      <header class="heroImg">
        <nav class="navbar navbar-expand-xl navbar navbar-dark bg-dark fixed-top">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <img src={logo} alt="logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarScroll">
              <ul class="navbar-nav leftme navbar-nav-scroll d-flex">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#about">
                    About
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#supply">
                    Supply
                  </a>
                </li>

                <li class="nav-item">
                  <a href="#starmap" class="nav-link">
                    Starmap
                  </a>
                </li>

                <li class="nav-item">
                  <a href="#community" class="nav-link">
                    Community
                  </a>
                </li>

                <li class="nav-item">
                  <a href="#artical" class="nav-link">
                    Articles
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#whitepaper" class="nav-link">
                    AlienPaper
                  </a>
                </li>

                <li class="nav-item">
                  <a href="#listed" class="nav-link">
                    Buy A4M
                  </a>
                </li>

                <li class="nav-item">
                  <ConnectButton />
                </li>

                {/* {token && (  */}
                <li class="nav-item">
                  <div class="btn" onClick={() => redirectToTrading(true)}>
                    <a href={"#"} class="btn-content">
                      Go to Probe
                    </a>
                  </div>
                </li>
                {/* )} */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
