import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import useAuthStore from "../store/useAuthStore";
import { useAccount } from "wagmi";

function Subscription() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { address, isConnected } = useAccount();
  const setToken = useAuthStore((state) => state.setToken);
  const token = useAuthStore((state) => state.token);
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(false);

  // Function to handle the toggle
  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl =
      "https://k4yiqqv97h.execute-api.us-east-1.amazonaws.com/CreateCharge";
    const payload = {
      username: address,
      amount: !isAnnual ? 50 : 500,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      window.location.href = data.checkoutUrl;
      // window.open(data.checkoutUrl, "_blank");
    } catch (error) {
      console.error("Error:", error);
      alert("API call failed");
    }
  };

  return (
    <>
      {/* <section classNameName="Whitepaper" id="whitepaper">
        <div classNameName="container">
          <div classNameName="d-flex justify-content-center align-items-center h-100">
            <div
              id="center-box"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div style={centerBoxStyle}>
                <h3>Subcription</h3>
                <form onSubmit={handleSubmit}>
                  <button type="submit" classNameName="btn ">
                    <div classNameName="btn-content">Buy Membership</div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class="pt-100">
        <div class="container-fluid mb-2">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 text-align-center mb-40px">
              <h1 class="title">Subscription page</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-25 text-align-center" id="about">
        <div className="container">
          <div className="row">
            <div className="suB col-lg-8 col-md-8 col-sm-12 col-md-12 offset-lg-2 offset-sm-0">
              <div className="card_">
                <h5>Subscribe to probe to get access </h5>

                <div className="slideToggle">
                  <label className="form-switch">
                    <span
                      className={`beforeinput ${!isAnnual && "text-success"} `}
                    >
                      MONTHLY
                    </span>
                    <input
                      type="checkbox"
                      id="js-contcheckbox"
                      checked={isAnnual}
                      onChange={handleToggle}
                    />
                    <i></i>
                    <span
                      className={`afterinput ${isAnnual && "text-success"}`}
                    >
                      ANNUAL
                    </span>
                  </label>
                </div>

                <div className="price_">{!isAnnual ? "50$" : "500$"} </div>
                <div className="subBtn">
                  <button className="btn_tr" onClick={handleSubmit}>
                    Subscribe
                  </button>
                  <button className="btn_tr hidden">Connect to Probe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section classNameName="h-200 text-align-center " id="about">
        <div classNameName="container">
          <div classNameName="row">
            <div
              classNameName="suB col-lg-8 col-md-8 col-sm-12 col-md-12 offset-lg-2 offset-sm-0"
              data-aos="fade-right"
            >
              <h2>Subscription page</h2>
              <div classNameName="card_">
                <h5>Subscribe to probe to get access </h5>
                <div classNameName="slideToggle">
                  <label classNameName="form-switch">
                    <span classNameName="beforeinput text-success">MONTHLY</span>
                    <input type="checkbox" id="js-contcheckbox" />
                    <i></i>
                    <span classNameName="afterinput">ANNUAL</span>
                  </label>
                </div>
                <div classNameName="price_">50$</div>
                <div classNameName="subBtn">
                  <button classNameName="btn_tr" onClick={handleSubmit}>
                    Subscribe
                  </button>
                  <button classNameName="btn_tr hidden">Connect to Probe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Subscription;
