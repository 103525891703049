// src/store/useAuthStore.js
import create from "zustand";

const useAuthStore = create((set) => ({
  token: localStorage.getItem("token")
    ? localStorage.getItem("token")
    : undefined, // Initial state of the token
  setToken: (token) => set(() => ({ token })), // Action to update the token
  clearToken: () => set(() => ({ token: undefined })), // Action to clear the token
}));

export default useAuthStore;
