import React from "react";

export default function () {
  return (
    <div>
      <section className="pt-100 " style={{background:"black"}} >
        <div className="container-fluid mb-40px">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 text-align-center mb-40px"
              
            >
              <h1 className="title">
                AlienForm - Privacy Policy for AlienForm Corp.
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-50 pb-100" id="">
        <div className="container mb-40px">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12  mb-40px"
              
            >
              <h3 className="subTitleA">Effective Date: March 20, 2024</h3>
              <p>
                Thank you for visiting AlienForm ("we," "our," or "us"). This
                Privacy Policy describes how we collect, use, and disclose
                information when you access or use our website and services. By
                using AlienForm, you agree to the terms outlined in this Privacy
                Policy.
              </p>
              <h3 className="subTitleA">1. Information Collection and Use:</h3>

              <ol className="termCond">
                <li>
                  Personal Information: We may collect personal information such
                  as your name, email address, and payment details when you
                  subscribe to our services.
                </li>
                <li>
                  Usage Data: We collect information about how you interact with
                  our website, including your IP address, browser type, pages
                  visited, and timestamps.
                </li>
              </ol>

              <h3 className="subTitleA">2. Use of Information:</h3>

              <ol className="termCond">
                <li>
                  We use the information collected to provide and improve our
                  services, personalize your experience, and communicate with
                  you.
                </li>
                <li>
                  Personal information may be used to process subscriptions,
                  facilitate transactions, and respond to inquiries.
                </li>
              </ol>

              <h3 className="subTitleA">3. Data Security:</h3>

              <ol className="termCond">
                <li>
                  We implement appropriate security measures to protect your
                  personal information against unauthorized access, alteration,
                  disclosure, or destruction.
                </li>
                <li>
                  However, no method of transmission over the internet or
                  electronic storage is 100% secure. Therefore, we cannot
                  guarantee absolute security.
                </li>
              </ol>
              <h3 className="subTitleA">4. Third-Party Services:</h3>

              <ol className="termCond">
                <li>
                  AlienForm may utilize third-party services, such as payment
                  processors, analytics providers, and advertising partners.
                  These services may collect information about your interactions
                  with our website.
                </li>
                <li>
                  We encourage you to review the privacy policies of these
                  third-party services for their practices.
                </li>
              </ol>

              <h3 className="subTitleA">5. Cookies:</h3>

              <ol className="termCond">
                <li>
                  We use cookies and similar tracking technologies to enhance
                  your browsing experience and collect usage data. You can
                  adjust your browser settings to refuse cookies or alert you
                  when cookies are being sent.
                </li>
              </ol>

              <h3 className="subTitleA">6. Data Retention:</h3>

              <ol className="termCond">
                <li>
                  We retain personal information for as long as necessary to
                  fulfill the purposes outlined in this Privacy Policy, unless a
                  longer retention period is required by law.
                </li>
              </ol>

              <h3 className="subTitleA">7. Children's Privacy:</h3>

              <ol className="termCond">
                <li>
                  AlienForm is not directed towards individuals under the age of
                  18. We do not knowingly collect personal information from
                  children. If you believe that we have inadvertently collected
                  personal information from a child, please contact us
                  immediately.
                </li>
              </ol>

              <h3 className="subTitleA">Changes to Privacy Policy:</h3>

              <ol className="termCond">
                <li>
                  We reserve the right to update this Privacy Policy at any
                  time. Any changes will be e5ective immediately upon posting
                  the revised policy on our website.
                </li>
              </ol>
              <h3 className="subTitleA">Contact Us:</h3>

              <ol className="termCond">
                <li>
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy, please contact us at support@alien4m.com.
                </li>
              </ol>

              <p>
                By using AlienForm, you consent to the collection and use of
                your information as described in this Privacy Policy. It is your
                responsibility to review this policy periodically for any
                updates or changes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
