import React from 'react'
import { Outlet, Link } from "react-router-dom";

import Header from './Header'
import Home from './Home'
import Footer from './Footer'

function Layout() {
  return (
    <div>
         <Header/>
         <Outlet />
         <Footer/>
    </div>
  )
}

export default Layout