import React from "react";

function TermsofService() {
  return (
    <div>
      <section className="pt-100" style={{ background: "black" }}>
        <div className="container-fluid mb-40px">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 text-align-center mb-40px"
              
            >
              <h1 className="title">AlienForm - Terms & Conditions</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-50 pb-100" id="">
        <div className="container mb-40px">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12  mb-40px"
              
            >
              <h3 className="subTitleA">
                Last Updated: March 2, 2024 (Earth Calendar)
              </h3>
              <p>
                Welcome to AlienForm, Earthling! Please read the following Terms
                & Conditions carefully before using our services.
              </p>

              <h3 className="subTitleA mt-4">1. Acceptance of Terms</h3>
              <p>
                By accessing or using AlienForm's platforms, you agree to these
                Terms & Conditions and all applicable laws and regulations. If
                you do not agree with any part of these terms, you may not use
                our platforms.
              </p>

              <h3 className="subTitleA mt-4">2. Description of Services</h3>
              <p>
                AlienForm provides various crypto-related services, including
                but not limited to trading, portfolio management, and
                educational content. We reserve the right to modify or
                discontinue any service at any time without notice.
              </p>

              <h3 className="subTitleA mt-4">3. Eligibility</h3>
              <p>
                You must be at least 18 years old or have consent from a legal
                guardian to use our platforms. By using our services, you
                represent and warrant that you or your legal guardian are of
                legal age and have the capacity to accept these terms.
              </p>

              <h3 className="subTitleA mt-4">4. User Registration</h3>
              <p>
                To access certain features of our platforms, you may be required
                to register for an account. You agree to provide accurate,
                current, and complete information during the registration
                process and to update such information to keep it accurate,
                current, and complete.
              </p>

              <h3 className="subTitleA mt-4">5. Subscription Services</h3>
              <p>
                If you choose to subscribe to AlienForm's premium services, you
                agree to the following additional terms:
              </p>
              <ol className="termCond mt-4 mb-5">
                <li>
                  <b>Subscription Plans</b>
                  AlienForm offers various subscription plans with different
                  features and pricing. Details of the plans, including costs
                  and billing cycles, can be found on our platforms. By
                  subscribing, you agree to pay the applicable fees for the
                  selected plan.
                </li>
                <li>
                  <b>Billing and Payments</b>
                  Subscription fees will be billed on a recurring basis in
                  accordance with the selected billing cycle. You authorize
                  AlienForm to charge the designated payment method for the
                  subscription fees. It is your responsibility to ensure that
                  your payment information is accurate and up-to-date.
                </li>
                <li>
                  <b>Cancellation and Refunds</b>
                  You may cancel your subscription at any time. Cancellations
                  will be effective at the end of the current billing cycle, and
                  no refunds will be provided for the remaining unused portion
                  of the subscription.
                </li>
                <li>
                  <b>Changes to Subscription Plans</b>
                  AlienForm reserves the right to modify, suspend, or terminate
                  subscription plans at any time. In the event of a modification
                  that materially affects your subscription, we will provide
                  notice in advance.
                </li>
                <li>
                  <b>Free Trials</b>
                  From time to time, AlienForm may offer free trials for its
                  subscription services. Free trial eligibility and duration are
                  at the sole discretion of AlienForm. At the end of the trial
                  period, you will be automatically enrolled in the paid
                  subscription plan unless you cancel before the trial expires.
                </li>
                <li>
                  <b>Termination of Subscription Services</b>
                  AlienForm reserves the right to terminate or suspend
                  subscription services for any reason, including but not
                  limited to violations of these Terms & Conditions.
                </li>
                <li>
                  <b>No Investment Advice</b>
                  Subscription services provided by AlienForm are for
                  informational and educational purposes only and do not
                  constitute financial, investment, or trading advice. You are
                  solely responsible for your investment decisions.
                </li>
                <li>
                  <b>Communication</b>
                  By subscribing to AlienForm's premium services, you agree to
                  receive communications related to your subscription, including
                  renewal notices and promotional messages. You can opt out of
                  promotional messages at any time.
                </li>
                <li>
                  <b>Governing Law</b>
                  Subscription services are subject to the same Terms &
                  Conditions outlined in this document, and any disputes related
                  to subscription services are governed by the applicable
                  sections of these Terms & Conditions.
                </li>
              </ol>
              <h3 className="subTitleA mt-4">6.Security</h3>
              <p>
                You are responsible for maintaining the confidentiality of your
                account and password and for restricting access to your computer
                or device. You agree to accept responsibility for all activities
                that occur under your account(s).
              </p>
              <h3 className="subTitleA mt-4">7. Risks of Crypto Trading</h3>
              <p>
                Crypto trading involves a high level of risk, and prices can be
                extremely volatile. You acknowledge and agree that you are
                solely responsible for any losses or gains resulting from your
                investment decisions.
              </p>
              <h3 className="subTitleA mt-4">8. Prohibited Activities</h3>
              <p>
                You agree not to engage in any of the following activities:
                <ul className="list_">
                  <li>Violating any laws or regulations</li>
                  <li>
                    Using our platforms for illegal or fraudulent purposes
                  </li>
                  <li>
                    Attempting to gain unauthorized access to our systems or
                    networks
                  </li>
                  <li>Interfering with the proper working of our platforms</li>
                  <li>Engaging in any form of market manipulation</li>
                </ul>
              </p>
              <h3 className="subTitleA mt-4">9. Intellectual Property</h3>
              <p>
                All content on AlienForm's platforms, including text, graphics,
                logos, and images, is the property of AlienForm and is protected
                by intellectual property laws. You may not use, reproduce, or
                distribute any content from our platforms without our prior
                written consent.
              </p>
              <h3 className="subTitleA mt-4">10. Privacy Policy</h3>
              <p>
                Our Privacy Policy outlines how we collect, use, and disclose
                your personal information. By using our platforms, you agree to
                the terms of our Privacy Policy.
              </p>
              <h3 className="subTitleA mt-4">11. Termination</h3>
              <p>
                We reserve the right to terminate or suspend your account(s) and
                access to our platforms at any time for any reason without
                notice.
              </p>
              <h3 className="subTitleA mt-4">
                12. Changes to Terms & Conditions
              </h3>
              <p>
                We may update these Terms & Conditions from time to time. The
                date of the latest revision will be indicated at the top of this
                document. Continued use of our platforms after any such changes
                constitutes your acceptance of the new terms.
              </p>
              <h3 className="subTitleA mt-4">13. Contact Information</h3>
              <p>
                If you have any questions about these Terms & Conditions, please
                contact us at support@alien4m.com
              </p>
              <p>Thank you for choosing AlienForm!</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TermsofService;
