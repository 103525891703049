import ReactDOM from "react-dom/client";
import logo from "./logo.svg";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet, polygon, optimism, arbitrum, base, bsc } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Layout from "./components/Layout";
import Disclaimer from "./components/Disclaimer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsofService from "./components/TermsofService";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Login from "./components/Login";
// import Signup from "./components/Signup";
import Subscription from "./components/Subscription";
const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "2c0b31e68b5bf3bb848669cce5523bbe",
  chains: [mainnet, bsc],
  ssr: true, // If your dApp uses server side rendering (SSR)
});
const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider chains={config.chains}>
            <div className="App">
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="disclaimer" element={<Disclaimer />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-service" element={<TermsofService />} />
                  <Route path="subscription" element={<Subscription />} />
                  {/* <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} /> */}
                </Route>
              </Routes>
            </div>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </BrowserRouter>
  );
}

export default App;
