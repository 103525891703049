import React from "react";

function Disclaimer() {
  return (
    <div>
      <section className="pt-100" style={{ background: "black" }}>
        <div className="container-fluid mb-40px">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 text-align-center mb-40px"
              
            >
              <h1 className="title">AlienForm - Disclaimer.</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-50 pb-100" id="">
        <div className="container mb-40px">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 mb-40px"
              
            >
              <p>
                The information provided on AlienForm’s (A4M) Website, Videos,
                Social Channels and illustrations are for educational purposes
                only and do not constitute investment advice, financial advice,
                trading advice, or any other sort of advice and you should not
                treat any of the content as such. AlienForm does not recommend
                that any cryptocurrency should be bought, sold, or held by you.
                It is imperative that you conduct your own due diligence and
                consult a licensed financial advisor before making any
                investment decisions.
              </p>
              <h3 className="subTitleA">
                Copyright © 2023 AlienForm, A4M. All rights are reserved.
              </h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Disclaimer;
